<template>
  <div class="mb-3 card">
    <div>
      <v-tabs v-model="tab" class="rounded-t">
        <v-tab key="baseData">
          {{ $t('erp.lang_nav_waregroups') }}
        </v-tab>
        <v-tab v-if="this.isTranslationEnabled" key="translation">
          {{ $t('erp.lang_translation') }}
        </v-tab>
        <v-tab :disabled="!loadingGet" key="extras" v-if="this.$store.getters['permissions/checkModule'](19)">
          {{ $t('erp.lang_extras') }}
        </v-tab>
        <v-tab :disabled="!loadingGet" key="group_images" v-if="!isHybrid">
          {{ $t('erp.lang_itemgroupImages') }}
        </v-tab>
        <v-tabs-items touchless v-model="tab">
          <br>
          <v-tab-item key="baseData">
            <v-layout>
              <!-- Table: Click -->
              <v-flex md5 mr-2 ml-4>
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="itemGroupName"
                              :label="$t('erp.lang_itemgroup_name')"
                              autocomplete="off"
                              required
                ></v-text-field>
              </v-flex>
              <v-flex md2 mr-2 ml-2>
              </v-flex>
              <v-flex md5 mr-4 ml-2>
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="itemPriceCalcSurplus"
                              :label="$t('erp.lang_surplusToBuyPrice')"
                              autocomplete="off"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <!-- Table: Click -->
              <v-flex md5 mr-2 ml-4>
                <v-select outlined
                          v-if="this.$store.getters['permissions/checkModule'](2)"
                          :items="itemGroupOrderbonIDSelector"
                          item-text="name"
                          item-value="id"
                          v-model="itemGroupStdOrderPrinterID"
                          :label="$t('settings.langOrderbonPrinter')"
                ></v-select>

                <v-select outlined
                          v-if="this.$store.getters['permissions/checkModule'](2)"
                          :items="itemGroupOrderbonIDSelector"
                          item-text="name"
                          item-value="id"
                          v-model="dupItemGroupStdOrderPrinterID"
                          :label="$t('settings.lang_duplicateToOrderbonPrinter')"
                ></v-select>
              </v-flex>
              <v-flex md2 mr-2 ml-2>
              </v-flex>
              <v-flex md5 mr-4 ml-2>
                <v-select outlined
                          v-if="this.$store.getters['permissions/checkModule'](30)"
                          :items="itemGroupCourseIDSelector"
                          item-text="name"
                          item-value="id"
                          :clearable=true
                          v-model="itemGroupStdCourseID"
                          :label="$t('erp.lang_stadCourseIDWG')"
                ></v-select>

                <v-select outlined v-if="this.$store.getters['permissions/checkModule'](10)"
                          :items="kitchenMonitors"
                          item-text="1"
                          item-value="0" multiple chips
                          v-model="selectedKitchenMonitors"
                          :label="$t('erp.lang_displayKitchenMonitor')"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout>
              <!-- Table: Click -->
              <v-flex md5 mr-2 ml-4>
                <strong>{{ $t('erp.lang_colourofWaregroup') }}:</strong>
                <v-color-picker
									v-model="itemGroupColorPicker"
									mode="hexa"
									show-swatches
									hide-mode-switch
                  hide-canvas
									elevation="1"
									width="100%"
									class="color-swatcher-without-transparency mx-auto my-8"
								></v-color-picker>

                <br>

                <fontawesome-picker :fieldLabel="$t('erp.lang_chooseWaregroupIcon')" :value="itemGroupIcon"
                                    v-model="itemGroupIcon"></fontawesome-picker>


                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="pmsItemizer"
                              :label="$t('erp.lang_pms_itemizer')"
                              autocomplete="off"
                              required
                ></v-text-field>

                <v-textarea v-model="itemGroupDescription" @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                            autocomplete="off" outlined
                            dense/>

                <div v-if="this.$store.getters['permissions/checkPermission']('itemgroupMassChangeTax')"
                     style="border: solid;border-radius:5px;border-color: #f00;padding:5px;">
                  {{ $t('erp.lang_changeAllTaxForItemsBelongingInItemgroup') }}
                  <v-select outlined
                            :items="itemGroupTaxIDSelector"
                            item-text="name"
                            item-value="id"
                            :clearable=true
                            v-model="itemGroupOverwriteTaxId"
                            :label="$t('erp.lang_tax_value')"
                  ></v-select>
                  <v-btn
                      dark
                      color="primary"
                      class="ma-9 white--text"
                      @click="applyTaxes"
                  >
                    {{ $t('generic.lang_applyToItems') }}
                    <v-icon
                        right
                        dark
                    >
                      mdi-check
                    </v-icon>
                  </v-btn>
                </div>
              </v-flex>
              <v-flex md2 mr-2 ml-2>
              </v-flex>
              <v-flex md5 mr-4 ml-2>

                <v-switch
                    v-model="itemGroupColorSwitch"
                    :label="$t('erp.lang_colourWares')"
                ></v-switch>

                <v-switch
                    v-model="itemGroupDisplaySwitch"
                    :label="$t('erp.lang_displayWaregroup')"
                ></v-switch>

                <v-switch
                    v-if="this.$store.getters['permissions/checkModule'](10)"
                    v-model="displayInKitchenMonitor"
                    :label="$t('erp.lang_displayKitchenMonitor')"
                ></v-switch>


                <strong>{{ $t('erp.mealType') }}</strong><br>

                <v-radio-group row v-model="itemGroupTypeChooser">
                  <v-radio v-if="this.$store.getters['permissions/checkModule'](2)"
                           :key="0"
                           :value="1"
                  >
                    <template slot="label">
                      <div>
                        <font-awesome-icon :icon="['fal', 'cocktail']" size="3x"/>
                        <p>{{ $t('generic.lang_beverage') }}</p></div>
                    </template>
                  </v-radio>

                  <v-radio v-if="this.$store.getters['permissions/checkModule'](2)"
                           :key="1"
                           :value="2"
                  >
                    <template slot="label">
                      <div>
                        <font-awesome-icon :icon="['fal', 'meat']" size="3x"/>
                        <p>{{ $t('generic.lang_food') }}</p></div>
                    </template>
                  </v-radio>

                  <v-radio
                      :key="2"
                      :label="`Radio`"
                      :value="3"
                  >
                    <template slot="label">
                      <div>
                        <font-awesome-icon :icon="['fal', 'gifts']" size="3x"/>
                        <p>{{ $t('generic.lang_item') }}</p></div>
                    </template>
                  </v-radio>
                  <v-radio
                      :key="3"
                      :label="`Radio`"
                      :value="4"
                  >
                    <template slot="label">
                      <div>
                        <font-awesome-icon :icon="['fal', 'concierge-bell']" size="3x"/>
                        <p>{{ $t('generic.lang_service') }}</p></div>
                    </template>
                    <template slot="label">
                      <v-btn
                          dark
                          color="primary"
                          class="ma-9 white--text"
                          @click="applyTypes"
                      >
                        {{ $t('generic.lang_applyToItems') }}
                        <v-icon
                            right
                            dark
                        >
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-radio-group v-model="ageVerification">
                  <v-radio
                      key="1"
                      :label="$t('erp.lang_ageVerification') +' '+ $t('settings.lang_settings_deactiviert')"
                      value="0"
                  ></v-radio>
                  <v-radio
                      key="2"
                      :label="$t('erp.lang_ageVerification') + ' 16 ' + $t('generic.lang_yearsOfAge')"
                      value="16"
                  ></v-radio>
                  <v-radio
                      key="3"
                      :label="$t('erp.lang_ageVerification') +' 18 ' + $t('generic.lang_yearsOfAge')"
                      value="18"
                  ></v-radio>
                </v-radio-group>

                <div class="mb-8" style="border: solid; border-radius: 5px; border-color: #f00; padding: 5px;">
                  <font-awesome-icon
                    style="margin-right: 10px; float: left"
                    :icon="['fal', 'person-carry']"
                    size="3x"
                  />
                  <v-switch   
                    v-model="takeAwayTaxing"
                    :true-value=1
                    :false-value=0
                    hide-details
                    :label="$t('settings.lang_warecreate_taxsettingouterhouse')"
                  >
                  </v-switch>

                  <v-btn
                      dark
                      color="primary"
                      class="ma-9 white--text"
                      @click="applyTaxForTakeawaySales"
                  >
                    {{ $t('generic.lang_applyToItems') }}
                    <v-icon right dark>
                      mdi-check
                    </v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-tab-item>

          <!--start: attributes translation-->
          <v-tab-item v-if="this.isTranslationEnabled" key="translation">
            <b-tabs>

              <!-- germany -->
              <b-tab active>
                <template #title>
                  <v-btn text class="ma-0">
                    <country-flag country="DE"/>
                  </v-btn>
                </template>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-text-field @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="itemGroupName_de"
                                  :label="$t('erp.lang_itemgroup_name')"
                                  autocomplete="off" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <!--                    <v-textarea v-model="itemGroupDescription_de" @focus="showTouchKeyboard"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                                    autocomplete="off" outlined
                                                    dense/>-->
                    <label>{{ $t('generic.lang_description') }}</label>
                    <quill-editor @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" output="html"
                                  class="mb-4" style="height: 150px" :options="toolbarOptions"
                                  v-model="itemGroupDescription_de"></quill-editor>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- english -->
              <b-tab>
                <template #title>
                  <v-btn text class="ma-0">
                    <country-flag country="GB"/>
                  </v-btn>
                </template>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-text-field @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="itemGroupName_en"
                                  :label="$t('erp.lang_itemgroup_name')"
                                  autocomplete="off" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <!--                    <v-textarea v-model="itemGroupDescription_en" @focus="showTouchKeyboard"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                                    autocomplete="off" outlined
                                                    dense/>-->
                    <label>{{ $t('generic.lang_description') }}</label>
                    <quill-editor @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" output="html"
                                  class="mb-4" style="height: 150px" :options="toolbarOptions"
                                  v-model="itemGroupDescription_en"></quill-editor>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- french -->
              <b-tab>
                <template #title>
                  <v-btn text class="ma-0">
                    <country-flag country="FR"/>
                  </v-btn>
                </template>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-text-field @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="itemGroupName_fr"
                                  :label="$t('erp.lang_itemgroup_name')"
                                  autocomplete="off" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <!--                    <v-textarea v-model="itemGroupDescription_fr" @focus="showTouchKeyboard"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                                    autocomplete="off" outlined
                                                    dense/>-->
                    <label>{{ $t('generic.lang_description') }}</label>
                    <quill-editor @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" output="html"
                                  class="mb-4" style="height: 150px" :options="toolbarOptions"
                                  v-model="itemGroupDescription_fr"></quill-editor>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- arabic -->
              <b-tab>
                <template #title>
                  <v-btn text class="ma-0">
                    <country-flag country="SA"/>
                  </v-btn>
                </template>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-text-field @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="itemGroupName_ar"
                                  :label="$t('erp.lang_itemgroup_name')"
                                  autocomplete="off" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <!--                    <v-textarea v-model="itemGroupDescription_ar" @focus="showTouchKeyboard"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                                    autocomplete="off" outlined
                                                    dense/>-->
                    <label>{{ $t('generic.lang_description') }}</label>
                    <quill-editor @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" output="html"
                                  class="mb-4" style="height: 150px" :options="toolbarOptions"
                                  v-model="itemGroupDescription_ar"></quill-editor>
                  </v-col>
                </v-row>
              </b-tab>
            </b-tabs>
          </v-tab-item>
          <!--end: attributes translation-->
          <v-tab-item key="extras" v-if="this.$store.getters['permissions/checkModule'](19)">
            <EditItemgroupExtras v-model="extras_notes" :dont-ask-for-extras-prop="dontAskForExtras"
                                 :itemgroup-i-d="id"></EditItemgroupExtras>
          </v-tab-item>
          <!-- //todo:YO This  Component responsible of UpLoding image for (lunche_bee_items&local_bee_items&selfe service)-->
          <!-- Start of group_images-->
          <v-tab-item key="group_images"  v-if="!isHybrid">
            <v-card :loading="loading" elevation="0">
              <v-card-text>
                <v-container>
                  <v-row v-if="!loading" align="center" class="pa-5" justify="center">
                    <!--new logos-->
                    <v-col v-if="$store.getters['permissions/checkModule'](39)" cols="12" sm="6">
                      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border shadow-none">
                        <div class="pa-0 ma-0 dropdown-menu-header">

                          <div :class="[this.$vuetify.theme.dark? 'text-light' : 'text-dark']" class="card-header bg-muted">
                            {{$t('generic.localbee_image')}}
                          </div>

                        </div>
                        <div class="card-body">
                          <image-cropper ref="localbee_image" @update="updateImage('localbee_image')"
                                         v-model="logos.localbee_image"/>
                        </div>
                        <div class="d-block text-right card-footer pa-0">
                          <v-btn :disabled="loaders.localbee_image" :loading="loaders.localbee_image"
                                 block :class="[this.$vuetify.theme.dark? 'text-light' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                                 @click="updateData('localbee_image',
            'lunchbee_image','self_service_image', 'pos_image')">
                            <v-icon class="ma-1">cloud_upload</v-icon>
                            {{ this.$t('generic.lang_hochladen') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>

                    <v-col v-if="$store.getters['permissions/checkModule'](41)" cols="12" sm="6">
                      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border shadow-none">
                        <div class="pa-0 ma-0 dropdown-menu-header">

                          <div :class="[this.$vuetify.theme.dark? 'text-light' : 'text-dark']" class="card-header bg-muted">
                            {{$t('generic.lunchbee_image')}}
                          </div>

                        </div>
                        <div class="card-body">
                          <image-cropper ref="lunchbee_image" @update="updateImage('lunchbee_image')"
                                         v-model="logos.lunchbee_image"/>
                        </div>
                        <div class="d-block text-right card-footer pa-0">
                          <v-btn :disabled="loaders.lunchbee_image" :loading="loaders.lunchbee_image"
                                 block :class="[this.$vuetify.theme.dark? 'text-light' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                                 @click="updateData('localbee_image',
            'lunchbee_image','self_service_image', 'pos_image')">
                            <v-icon class="ma-1">cloud_upload</v-icon>
                            {{ this.$t('generic.lang_hochladen') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>

                    <v-col v-if="$store.getters['permissions/checkModule'](54) || $store.getters['permissions/checkModule'](67)" cols="12" sm="6">
                      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border shadow-none">
                        <div class="pa-0 ma-0 dropdown-menu-header">

                          <div :class="[this.$vuetify.theme.dark? 'text-light' : 'text-dark']" class="card-header bg-muted">
                            {{$t('generic.tablebee_image')}}
                          </div>

                        </div>
                        <div class="card-body">
                          <image-cropper ref="self_service_image" @update="updateImage('self_service_image')"
                                         v-model="logos.self_service_image"/>
                        </div>
                        <div class="d-block text-right card-footer pa-0">
                          <v-btn :disabled="loaders.self_service_image" :loading="loaders.self_service_image"
                                 block :class="[this.$vuetify.theme.dark? 'text-light' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                                 @click="updateData('localbee_image',
            'lunchbee_image','self_service_image', 'pos_image')">
                            <v-icon class="ma-1">cloud_upload</v-icon>
                            {{ this.$t('generic.lang_hochladen') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border shadow-none">
                        <div class="pa-0 ma-0 dropdown-menu-header">
                          <div :class="[this.$vuetify.theme.dark? 'text-light' : 'text-dark']" class="card-header bg-muted">
                            {{$t('generic.pos_image')}}
                          </div>
                        </div>
                        <div class="card-body">
                          <image-cropper ref="pos_image" v-model="logos.pos_image" @update="updateImage('pos_image')"/>
                        </div>
                        <div class="d-block text-right card-footer pa-0">
                          <v-btn :disabled="loaders.pos_image" :loading="loaders.pos_image"
                                 block :class="[this.$vuetify.theme.dark? 'text-light' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                                 @click="updateData('localbee_image', 'lunchbee_image','self_service_image', 'pos_image')">
                            <v-icon class="ma-1">cloud_upload</v-icon>
                            {{ this.$t('generic.lang_hochladen') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- end of group_images-->
        </v-tabs-items>

      </v-tabs>
      <v-container fluid :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
        <v-layout>
          <v-flex class="text-right">
            <v-btn text color="error" @click="goBacktoItemgroups">{{ $t('generic.lang_prev') }}</v-btn>
            <v-btn :disabled="this.bnt_loader" color="error"
                   v-if="this.$store.getters['permissions/checkPermission']('waregroups_delete')"
                   @click="deleteData">{{ $t('generic.lang_delete') }}
            </v-btn>
            <v-btn :loading="this.bnt_loader" color="success" :disabled="(itemGroupName.length < 1) || this.bnt_loader"
                   @click="updateData('localbee_image',
            'lunchbee_image','self_service_image', 'pos_image')">{{
                $t('generic.lang_save')
              }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import FontawesomePicker from "../../common/iconPicker";
import EditItemgroupExtras from "./editItemgroup/EditItemgroupExtras";
import ImageCropper from "@/components/common/imagecropper";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCocktail, faConciergeBell, faGifts, faMeat, faPersonCarry} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {createNamespacedHelpers} from "vuex";
import CountryFlag from "vue-country-flag";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

library.add(
    faCocktail,
    faMeat,
    faGifts,
    faConciergeBell,
    faPersonCarry,
);


export default {
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    FontawesomePicker,
    EditItemgroupExtras,
    ImageCropper,
    CountryFlag,
    quillEditor
  },

  mixins: [mixin],

  data() {
    return {
      toolbarOptions: {

        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'align': []}],
            [{'font': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ],
        },
      },
      extras_notes: {
        ExtrasNote_de: "",
        ExtrasNote_en: "",
        ExtrasNote_fr: "",
        ExtrasNote_ar: ""
      },
      logos: {
        localbee_image: "",
        lunchbee_image: "",
        self_service_image: "",
        pos_image: "",
      },
      updateFlags: {
        localbee_image: false,
        lunchbee_image: false,
        self_service_image: false,
        pos_image: false
      },
      loaders: {
        localbee_image: null,
        lunchbee_image: null,
        self_service_image: null,
        pos_image: null
      },
      types: {
        localbee_image: 1,
        lunchbee_image: 2,
        self_service_image: 3
      },
      ENDPOINTS,
      id: 0,
      itemGroupName: "",
      itemGroupName_de: "",
      itemGroupName_en: "",
      itemGroupName_fr: "",
      itemGroupName_ar: "",
      itemGroupDescription: "",
      itemGroupDescription_de: "",
      itemGroupDescription_en: "",
      itemGroupDescription_fr: "",
      itemGroupDescription_ar: "",
      itemPriceCalcSurplus: "",
      itemGroupStdOrderPrinterID: "",
      dupItemGroupStdOrderPrinterID: "",
      itemGroupStdCourseID: "",
      itemGroupOverwriteTaxId: "",
      itemgroupAdd: "",
      itemGroupTypeChooser: 3,
      displayInKitchenMonitor: true,
      itemGroupDisplaySwitch: true,
      itemGroupColorSwitch: true,
      itemGroupColorPicker: "",
      itemGroupOrderbonIDSelector: [],
      itemGroupCourseIDSelector: [],
      itemGroupTaxIDSelector: [],
      itemGroupIcon: '',
      takeAwayTaxing: 0,
      dontAskForExtras: false,
      ageVerification: "0",
      pmsItemizer: "",
      loading: false,
      loadingGet: false, // this loader wait data until get request finish
      tab: 0,
      bnt_loader: false,
      selectedKitchenMonitors: null,
      kitchenMonitors: [],
      loadingKitchenMonitors: false,
    }
  },

  watch: {},
  computed: {
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    },
    isHybrid(){
      return process.env.VUE_APP_HYBRID == "true" || this.$store.getters["permissions/checkHybridApp"]
    }
  },
  methods: {
    updateImage(type){
      this.updateFlags[type]=true;
    },
    goBacktoItemgroups: function () {
      this.$router.push('/erp/baseData/itemgroups');
    },
    applyTypes() {
      this.axios.post('update/erp/itemgrouptype/', {
        wareGroupID: this.id,
        itemGroupType: this.itemGroupTypeChooser
      }).then(res => {
        if (res.data) {
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_typeNotApplied'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.getData();
      })
    },


    applyTaxes() {

      if (this.itemGroupOverwriteTaxId > 0) {
        this.$swal({
          title: this.$t('erp.lang_waregroupsMassTaxChange'),
          text: this.$t('erp.lang_waregroupsMassTaxChangeText'),
          icon: "warning",
          confirmButtonText: this.$t('generic.lang_apply'),
          cancelButtonText: this.$t('generic.lang_cancel'),
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {

            this.axios.post('update/erp/itemgrouptaxes/', {
              wareGroupID: this.id,
              taxId: this.itemGroupOverwriteTaxId
            }).then(res => {
              if (res.data) {
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t('erp.lang_typeNotApplied'),
                  color: "error"
                });
              }
            }).catch(err => {
              Events.$emit("showSnackbar", {
                message: err.message,
                color: "error"
              });
            }).finally(() => {
              this.getData();
            })
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        });
      }
    },

    applyTaxForTakeawaySales() {
      this.$swal({
        title: this.$t('erp.lang_setDifferentTaxRateForTakeawaySalesMsgTitle'),
        text: this.$t('erp.lang_setDifferentTaxRateForTakeawaySalesMsgText'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText:this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_apply'),
        preConfirm: () => {
          this.axios.post('update/erp/itemgroupTakeAwayTax/', {
            wareGroupID: this.id,
            takeAwayTaxing: this.takeAwayTaxing
          }).then(res => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_ActionSuccessful'),
                color: "success",
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error"
            });
          }).finally(() => {
            this.getData();
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },

    deleteData: function (idsToDelete = []) {
      this.$swal({
        title: this.$t('erp.lang_waregroupsDelete'),
        text: this.$t('erp.lang_deleteAllergenText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.ERP.EDITITEMGROUP.DELETE, {
            waregroupID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_allergensDeleted'),
                color: "success"
              });

              this.resetData();
              this.goBacktoItemgroups();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    updateData: function (localbee_image, lunchbee_image, self_service_image, pos_image) {
      try {
        this.$refs[self_service_image].cropImage();
        this.loaders[self_service_image] = true;
      } catch (err) {
      }
      try {
        this.$refs[lunchbee_image].cropImage();
        this.loaders[lunchbee_image] = true;
      } catch (err) {
      }
      try {
        this.$refs[localbee_image].cropImage();
        this.loaders[localbee_image] = true;
      } catch (err) {
      }
      try {
        this.$refs[pos_image].cropImage();
        this.loaders[pos_image] = true;
      } catch (err) {
      }


      this.bnt_loader = true;
      this.axios.post(ENDPOINTS.ERP.EDITITEMGROUP.UPDATE, {
        waregroupID: this.id,
        wareGroupName: this.itemGroupName,
        wareGroupName_de: this.itemGroupName_de,
        wareGroupName_en: this.itemGroupName_en,
        wareGroupName_fr: this.itemGroupName_fr,
        wareGroupName_ar: this.itemGroupName_ar,
        description: this.itemGroupDescription,
        wareGroupEKAufschlag: this.itemPriceCalcSurplus,
        orderBonPrinterID: this.itemGroupStdOrderPrinterID,
        duplicate_orderbonPrinterID: this.dupItemGroupStdOrderPrinterID,
        editWaregroupColor: this.itemGroupColorPicker,
        itemGroupDisplayInKitchenMonitor: (this.displayInKitchenMonitor) ? 1 : 0,
        editWaregroupColourWares: this.itemGroupColorSwitch,
        editWaregroupDisplay: this.itemGroupDisplaySwitch,
        createWaregroupGastroFoodTypeChooser: this.itemGroupTypeChooser,
        waregroupIcon: this.itemGroupIcon,
        stdCourseWaregroupID: this.itemGroupStdCourseID,
        ageVerification: this.ageVerification,
        wareGroupPMSItemizer: this.pmsItemizer,
        self_service_image: this.updateFlags[self_service_image]?this.logos[self_service_image]:0,
        localbee_image: this.updateFlags[localbee_image]?this.logos[localbee_image]:0,
        lunchbee_image: this.updateFlags[lunchbee_image]?this.logos[lunchbee_image]:0,
        pos_image: this.updateFlags[pos_image]?this.logos[pos_image]:0,
        update_self_service_image: this.updateFlags[self_service_image],
        update_localbee_image: this.updateFlags[localbee_image],
        update_lunchbee_image: this.updateFlags[lunchbee_image],
        update_pos_image: this.updateFlags[pos_image],
        itemgroupKitchenMonitors: this.selectedKitchenMonitors,
        description_de: this.itemGroupDescription_de,
        description_en: this.itemGroupDescription_en,
        description_fr: this.itemGrgetoupDescription_fr,
        description_ar: this.itemGroupDescription_ar,
        ExtrasNote_de: this.extras_notes.ExtrasNote_de,
        ExtrasNote_en: this.extras_notes.ExtrasNote_en,
        ExtrasNote_fr: this.extras_notes.ExtrasNote_fr,
        ExtrasNote_ar: this.extras_notes.ExtrasNote_ar

      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemgroupCreated'),
            color: "success"
          });
          this.loaders[self_service_image] = false;
          this.loaders[lunchbee_image] = false;
          this.loaders[localbee_image] = false;
          this.loaders[pos_image] = false;
          this.goBacktoItemgroups();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.updateFlags[self_service_image]=false;
        this.updateFlags[localbee_image]=false;
        this.updateFlags[lunchbee_image]=false;
        this.updateFlags[pos_image]=false;
        this.bnt_loader = false
      });
    },
    getData: function () {
      this.id = parseInt(this.$parent.id);
      this.loadingGet = false,
          this.axios.post(ENDPOINTS.ERP.EDITITEMGROUP.GET, {
            waregroupID: this.$parent.id
          }).then((res) => {
            if (res.status === 200) {

              res.data.orderbonPrinter.forEach((row) => {
                this.itemGroupOrderbonIDSelector.push({name: row[1], id: row[0]});
              });

              res.data.courseIDs.forEach((row) => {
                this.itemGroupCourseIDSelector.push({name: row[1], id: parseInt(row[0])});
              });

              res.data.taxIDs.forEach((row) => {
                this.itemGroupTaxIDSelector.push({name: row[1], id: parseInt(row[0])});
              });

              this.itemGroupName = res.data.formfillData.textFields.wareGroupName;
              this.itemGroupName_de = res.data.formfillData.wareGroupName_de;
              this.itemGroupName_en = res.data.formfillData.wareGroupName_en;
              this.itemGroupName_fr = res.data.formfillData.wareGroupName_fr;
              this.itemGroupName_ar = res.data.formfillData.wareGroupName_ar;
              this.itemPriceCalcSurplus = res.data.formfillData.textFields.wareGroupEKAufschlag;
              this.itemGroupStdOrderPrinterID = parseInt(res.data.formfillData.waregroupOrderBonPrinterID);
              this.dupItemGroupStdOrderPrinterID = parseInt(res.data.formfillData.waregroup_duplicate_orderbonPrinterID);
              this.itemGroupStdCourseID = res.data.formfillData.stdCourseID;
              this.itemGroupTypeChooser = res.data.formfillData.gastroFoodType;
              this.ageVerification = res.data.formfillData.ageVerification.toString();
              this.displayInKitchenMonitor = res.data.formfillData.displayInKitchenMonitor;
              this.itemGroupDisplaySwitch = res.data.formfillData.waregroupDisplay;
              this.itemGroupColorSwitch = res.data.formfillData.waregroupColorAll;
              this.itemGroupColorPicker = res.data.formfillData.waregroupBackgroundColor;
              this.itemGroupIcon = res.data.formfillData.icon;
              this.dontAskForExtras = (res.data.formfillData.dontAskForExtras === 1);
              this.pmsItemizer = res.data.formfillData.textFields.wareGroupPMSItemizer;
              this.logos.localbee_image = Array.isArray(res.data.images) ? "" : res.data.images.localbee_image;
              this.logos.lunchbee_image = Array.isArray(res.data.images) ? "" : res.data.images.lunchbee_image;
              this.logos.self_service_image = Array.isArray(res.data.images) ? "" : res.data.images.self_service_image;
              this.logos.pos_image = Array.isArray(res.data.itemgroupPosImage) ? res.data.itemgroupPosImage[1] : "";
              this.loadingGet = true;
              this.selectedKitchenMonitors = res.data.formfillData.kitchenMonitorIDs;

              this.itemGroupDescription = res.data.formfillData.description;
              this.itemGroupDescription_de = res.data.formfillData.description_de;
              this.itemGroupDescription_en = res.data.formfillData.description_en;
              this.itemGroupDescription_fr = res.data.formfillData.description_fr;
              this.itemGroupDescription_ar = res.data.formfillData.description_ar;

              this.extras_notes.ExtrasNote_de = res.data.formfillData.extrasNote.ExtrasNote_de;
              this.extras_notes.ExtrasNote_fr = res.data.formfillData.extrasNote.ExtrasNote_fr;
              this.extras_notes.ExtrasNote_en = res.data.formfillData.extrasNote.ExtrasNote_en;
              this.extras_notes.ExtrasNote_ar = res.data.formfillData.extrasNote.ExtrasNote_ar;
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          });
    },


    resetData: function () {
      this.id = null;
      this.text = "";
    },
    getKitchenMonitors() {
      this.loadingKitchenMonitors = true;
      this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.GETALL).then(res => {
        if (res.data.SUCCESS) {
          this.kitchenMonitors = res.data['0']
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_errorWhileGettingKitchenMonitors'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_fehler') + ' : ' + err.message,
          color: "success"
        });
      }).finally(() => {
        this.loadingKitchenMonitors = false;
      })
    }
  },
  mounted() {
    this.getKitchenMonitors();
    this.getData();
  },
}
</script>

<style>
.color-swatcher-without-transparency
  .v-color-picker__sliders
  .v-color-picker__alpha {
  visibility: hidden;
}

.color-swatcher-without-transparency
  .v-color-picker__sliders
  .v-color-picker__hue {
  margin-bottom: 0 !important;
}

.color-swatcher-without-transparency
  .v-color-picker__swatch:nth-child(20)
  > .v-color-picker__color:nth-child(3) {
  visibility: hidden;
}
</style>
