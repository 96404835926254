<template>
  <v-container class="pt-0">
    <div class="text-md-center" style="margin: 50px;" v-if="loading && delegations.length === 0">
      <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    <v-row>

      <v-col cols="12">
        <v-expansion-panels class="elevation-1">
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t('erp.lang_extrasNote') }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-tabs v-model="lang_tabs" v-if="this.isTranslationEnabled">
                <v-tab>
                  <template v-slot:default>
                    <v-btn text class="ma-0">
                      <country-flag country="DE"/>
                    </v-btn>
                  </template>
                </v-tab>

                <v-tab>
                  <template v-slot:default>
                    <v-btn text class="ma-0">
                      <country-flag country="GB"/>
                    </v-btn>
                  </template>
                </v-tab>

                <v-tab>
                  <template v-slot:default>
                    <v-btn text class="ma-0">
                      <country-flag country="FR"/>
                    </v-btn>
                  </template>
                </v-tab>

                <v-tab>
                  <template v-slot:default>
                    <v-btn text class="ma-0">
                      <country-flag country="SA"/>
                    </v-btn>
                  </template>
                </v-tab>


                <v-tabs-items class="pt-3" v-model="lang_tabs">
                  <v-tab-item>
                    <v-row no-gutters align="center" justify="center">

                      <v-col cols="12" sm="8">
                        <v-textarea :value="this.value.ExtrasNote_de" @input="update('ExtrasNote_de', $event)"
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('erp.lang_extrasNote')"
                                    autocomplete="off" outlined
                                    dense/>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <!-- english tab -->
                  <v-tab-item>

                    <v-row no-gutters align="center" justify="center">

                      <v-col cols="12" sm="8">
                        <v-textarea :value="this.value.ExtrasNote_en" @input="update('ExtrasNote_en', $event)"
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('erp.lang_extrasNote')"
                                    autocomplete="off" outlined
                                    dense/>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <!-- french tab -->
                  <v-tab-item>
                    <v-row no-gutters align="center" justify="center">
                      <v-col cols="12" sm="8">
                        <v-textarea :value="this.value.ExtrasNote_fr" @input="update('ExtrasNote_fr', $event)"
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('erp.lang_extrasNote')"
                                    autocomplete="off" outlined
                                    dense/>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <!-- arabic tab -->
                  <v-tab-item>
                    <v-row no-gutters align="center" justify="center">

                      <v-col cols="12" sm="8">
                        <v-textarea :value="this.value.ExtrasNote_ar" @input="update('ExtrasNote_ar', $event)"
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('erp.lang_extrasNote')"
                                    autocomplete="off" outlined
                                    dense/>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
              <v-row v-else no-gutters align="center" justify="center">

                <v-col cols="12" sm="8">
                  <v-textarea v-model="this.value.ExtrasNote_de" @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('erp.lang_extrasNote')"
                              autocomplete="off" outlined
                              dense/>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-checkbox :label="$t('erp.lang_disableGarnishQuery')" v-model="dontAskForExtras"
                    :disabled="loadingDontAskForExtras" hide-details @click="updateDontAskForExtras"/>
      </v-col>
      <v-divider class="ma-0"/>
      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
        <v-autocomplete
            v-model="search"
            :items="delegations"
            outlined
            chips
            small-chips
            multiple
            deletable-chips
            item-text="1"
            item-value="0"
            @change="getDelegations"
            :label="$t('erp.lang_garnishName')"
            hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
        <v-autocomplete
            v-model="searchGroup"
            :items="garnishGroups"
            outlined
            chips
            small-chips
            multiple
            deletable-chips
            item-text="name"
            item-value="uuid"
            :label="$t('erp.lang_garnishGroups')"
            hide-
            @change="getDelegations"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-divider class="ma-0"/>
    <div>
      <div ref="topScroll" class="top-scroll" @scroll.passive="handleScroll">
        <div
        />
      </div>
      <div ref="content" class="content" @scroll.passive="handleScroll">
        <div
        >
          <v-data-table :headers="datatableHeaders"
                        :items="delegations"
                        hide-default-header
                        :loading="loading"
                        :options.sync="pagination"
                        :server-items-length="total"
                        :footer-props="footerProps">
            <template v-slot:header="{ headers }">
              <thead>
              <tr>
                <template v-for="(header, index) in datatableHeaders">
                  <th v-if="!header.hide" :key="header.text " class="text-center">
                    {{ header.text }}

                    <EditItemgroupExtrasActivateButton v-if="index > 3 && searchGroup.length === 1"
                                                       :delegations="delegations" :garnish-tab="header"
                                                       :itemgroup-i-d="itemgroupID"
                                                       @finish="getGarnishTabs"></EditItemgroupExtrasActivateButton>
                  </th>
                </template>

              </tr>
              </thead>
            </template>
            <template v-slot:body="{items}">
              <tbody>
              <tr :key="delegationIndex" v-for="(delegation, delegationIndex) in items">
                <template v-for="(header, index) in datatableHeaders">
                  <td v-if="index > 3" :key="header.text "
                      :style="{backgroundColor: (delegation[header.value].active ? '#42f566' : '')}">
                    <div>
                      <v-edit-dialog
                          :return-value.sync="delegation[header.value]"
                          @save="saveDelegation(delegation[header.value], parseInt(header.value)-2, delegation[0])"
                          :cancel-text="$t('generic.lang_cancel')"
                          large
                          lazy
                          :save-text="$t('generic.lang_save')"
                      >
                        <div>{{ delegation[header.value].price | currency }}</div>
                        <template v-slot:input>
                          <div class="mt-3 title">{{ $t('erp.lang_extras') }}</div>
                        </template>
                        <template v-slot:input>
                          <v-layout wrap>
                            <v-flex md3>
                              <v-checkbox
                                  :label="$t('settings.lang_settings_activ')"
                                  v-model="delegation[header.value].active"
                              ></v-checkbox>
                            </v-flex>

                            <v-flex md7 offset-md2>
                              <v-currency-field
                                  autofocus
                                  :label="$t('generic.lang_edit')"
                                  single-line
                                  v-model="delegation[header.value].price"/>
                            </v-flex>
                          </v-layout>
                        </template>
                      </v-edit-dialog>

                    </div>
                  </td>
                  <td v-else-if="!header.hide" :key="header.text ">
                    {{ delegation[header.value] }}
                  </td>
                </template>
              </tr>
              </tbody>
            </template>
          </v-data-table>
          <!--
          <v-simple-table v-if="delegations.length > 0&&false">
            <template v-slot:default>

              <thead>
              <tr>
                <template v-for="(header, index) in datatableHeaders">
                  <th v-if="!header.hide" :key="header.text " class="text-center">
                    {{ header.text }}

                    <EditItemgroupExtrasActivateButton v-if="index > 3 && searchGroup.length === 1"
                                                       :delegations="filteredData" :garnish-tab="header"
                                                       :itemgroup-i-d="itemgroupID"
                                                       @finish="getGarnishTabs"></EditItemgroupExtrasActivateButton>
                  </th>
                </template>

              </tr>
              </thead>
              <tbody>
              <tr :key="delegationIndex" v-for="(delegation, delegationIndex) in filteredData">
                <template v-for="(header, index) in datatableHeaders">
                  <td v-if="index > 3" :key="header.text "
                      :style="{backgroundColor: (delegation[header.value].active ? '#42f566' : '')}">
                    <div>
                      <v-edit-dialog
                          :return-value.sync="delegation[header.value]"
                          @save="saveDelegation(delegation[header.value], parseInt(header.value)-2, delegation[0])"
                          cancel-text="Abbrechen"
                          large
                          lazy
                          save-text="Speichern"
                      >
                        <div>{{ delegation[header.value].price | currency }}</div>
                        <template v-slot:input>
                          <div class="mt-3 title">Extras</div>
                        </template>
                        <template v-slot:input>
                          <v-layout wrap>
                            <v-flex md3>
                              <v-checkbox
                                  label="Aktiv"
                                  v-model="delegation[header.value].active"
                              ></v-checkbox>
                            </v-flex>

                            <v-flex md7 offset-md2>
                              <v-currency-field
                                  append-outer-icon="euro"
                                  autofocus
                                  counter
                                  label="Bearbeiten"
                                  single-line
                                  v-model="delegation[header.value].price"/>
                            </v-flex>
                          </v-layout>
                        </template>
                      </v-edit-dialog>

                    </div>
                  </td>
                  <td v-else-if="!header.hide" :key="header.text ">
                    {{ delegation[header.value] }}
                  </td>
                </template>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          -->
        </div>
      </div>
    </div>

  </v-container>
</template>


<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import EditItemgroupExtrasActivateButton
  from "@/components/erp/baseData/editItemgroup/EditItemgroupExtrasActivateButton";
import {createNamespacedHelpers} from "vuex";
import mixin from '../../../../mixins/KeyboardMixIns';
import CountryFlag from "vue-country-flag";

export default {
  name: "EditItemExtras",
  components: {EditItemgroupExtrasActivateButton, CountryFlag},
  mixins: [mixin],
  props: {
    itemgroupID: Number,
    dontAskForExtrasProp: Boolean,
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      lang_tabs: 0,
      garnishTabs: [],
      delegations: [],
      search: [],
      searchGroup: [],
      garnishGroups: [],
      total: 0,
      pagination: {},
      footerProps: {"items-per-page-options": [5,20, 40, 60, 100,-1]},
      dontAskForExtras: false,
      loadingDontAskForExtras: false,
      loading: false,
      arescrolling: 0,
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getDelegations();
      },
      deep: true,
    },
  },
  mounted() {
    this.dontAskForExtras = this.dontAskForExtrasProp
    this.getGarnishGroups();
    this.getGarnishTabs();
  },

  computed: {
    datatableHeaders() {
      let headers = [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: '0'
        },
        {
          text: this.$t("erp.lang_garnishName"),
          align: 'left',
          sortable: false,
          value: '1'
        },
        {
          text: 'uuid',
          align: 'left',
          sortable: false,
          value: '2',
          hide: true,
        },
        {
          text: this.$t("erp.lang_garnishGroup"),
          align: 'left',
          sortable: false,
          value: '3'
        },
      ];

      let counter = 4;

      this.garnishTabs.forEach((garnishTab) => {
        headers.push({
          text: garnishTab.name,
          sortable: false,
          value: counter.toString()
        });

        counter++;
      });

      return headers;
    },
    getDelegates() {
      return this.delegations
    },
    filteredData() {
      let filtered = [];

      if (this.search.length < 1 && this.searchGroup.length < 1) {
        filtered = this.delegations;
        return filtered;
      }

      filtered = this.getDelegates.filter(deleg => {
        if (this.search.length < 1 && this.searchGroup.length > 0) {
          return this.searchGroup.includes(deleg[2]);
        } else if (this.searchGroup.length < 1 && this.search.length > 0) {
          return this.search.includes(deleg[1]);
        } else {
          return this.search.includes(deleg[1]) || this.searchGroup.includes(deleg[2]);
        }
      })

      return filtered;
    },
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    }
  },

  methods: {
    update(key, value) {
      this.$emit('input', {...this.value, [key]: value})
    },
    scroller(from, to) {
      if (this.arescrolling) return; // avoid potential recursion/inefficiency
      this.arescrolling = 1;
      // set the other div's scroll position equal to ours
      document.getElementById(to).scrollLeft = document.getElementById(from).scrollLeft;
      this.arescrolling = 0;
    },
    getGarnishTabs() {
      this.loading = true;

      this.axios.post(ENDPOINTS.ERP.GARNISHTABS.GET).then((res) => {
        if (res.data.success) {
          this.garnishTabs = res.data.data;

          this.getDelegations();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_dataCannotBeDisplayed"),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t("generic.lang_dataCannotBeDisplayed"),
          color: "error"
        });

        this.loading = false;
      });
    },
    getGarnishGroups() {
      this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.garnishGroups = res.data.groups;
        }
      }).catch(err => {

      })
    },
    getDelegations() {
      this.loading = true;
      this.axios.get(ENDPOINTS.DATATABLES.ERP.DELEGATEGARNISHITEMGROUP, {
        params: {
          wgID: this.itemgroupID,
          sEcho: 1,
          iDisplayStart:this.pagination.itemsPerPage * (this.pagination.page - 1),
          iDisplayLength: this.pagination.itemsPerPage,
          garnishGroupUUID:this.searchGroup,
          garnishID:this.search,
        }
      }).then((res) => {
        let delegations = []
        this.total = parseInt(res.data.iTotalDisplayRecords);
        res.data.aaData.forEach((row) => {
          let data = {};

          this.datatableHeaders.forEach((header) => {
            data[header.value] = row[header.value];
          });

          delegations.push(data);

        });
        this.delegations = delegations;
      }).finally(()=>{
        this.loading = false;
      })
    },
    saveDelegation(extra, column, extraID) {
      this.loading = true;

      this.axios.post(ENDPOINTS.ERP.ITEMGROUP.DELEGATEGARNISH.UPDATE, {
        row_id: extraID,
        column: column,
        extraPrice: extra.price,
        waregroupID: 0,
        editItemgroupID: this.itemgroupID,
        value: extra.active
      }).then((res) => {
        this.loading = false;

        if (!res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_dataCouldNotBeSaved"),
            color: "error"
          });
        }

        this.getGarnishTabs();
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t("generic.lang_dataCouldNotBeSaved"),
          color: "error"
        });

        this.getGarnishTabs();
      });
    },
    handleScroll(event) {
      if (event.target._prevClass === 'content') {
        this.$refs.topScroll.scrollLeft = this.$refs.content.scrollLeft
      } else {
        this.$refs.content.scrollLeft = this.$refs.topScroll.scrollLeft
      }
    },
    updateDontAskForExtras() {
      this.loadingDontAskForExtras = true
      this.axios.post(ENDPOINTS.ERP.ITEMGROUP.DONTASKFOREXTRAS, {
        itemgroupID: this.itemgroupID,
        dontAskForExtras: (this.dontAskForExtras) ? 1 : 0,
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_updatedSuccessfully"),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.STATUS,
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingDontAskForExtras = false;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.top-scroll,
.content {
  overflow: auto;
  max-width: 100%;
  width: 100%;
}

.top-scroll {
  margin-top: 50px;
}
</style>
