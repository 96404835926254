<template>
<div>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          icon
      >
        <v-icon>settings</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="activate">
        <v-list-item-title>{{ $t('generic.lang_activateAll') }}</v-list-item-title>
      </v-list-item>

      <v-list-item @click="deactivate">
        <v-list-item-title>{{ $t('generic.lang_disableAll') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <!-- Loading Dialog -->
  <v-dialog persistent max-width="400" :value="loading">
    <v-card>
      <v-card-text class="text-center">
        <v-progress-circular style="margin-top: 20px;" width="3" size="50" indeterminate color="primary"></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "EditItemgroupExtrasActivateButton",

  props: {
    delegations: Array,
    garnishTab: Object,
    itemgroupID: Number
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    async activate() {
      this.loading = true;

      for (let key = 0; this.delegations.length > key; key++) {
        let delegation = this.delegations[key];

        if (!delegation[this.garnishTab.value].active) {
          await this.axios.post(ENDPOINTS.ERP.ITEMGROUP.DELEGATEGARNISH.UPDATE, {
            row_id: delegation[0],
            column: parseInt(this.garnishTab.value) - 2,
            extraPrice: delegation[this.garnishTab.value].price,
            waregroupID: 0,
            editItemgroupID: this.itemgroupID,
            value: true,
          })
        }
      }

      this.loading = false;
      this.$emit("finish");
    },
    async deactivate() {
      this.loading = true;

      for (let key = 0; this.delegations.length > key; key++) {
        let delegation = this.delegations[key];

        if (delegation[this.garnishTab.value].active) {
          await this.axios.post(ENDPOINTS.ERP.ITEMGROUP.DELEGATEGARNISH.UPDATE, {
            row_id: delegation[0],
            column: parseInt(this.garnishTab.value) - 2,
            extraPrice: delegation[this.garnishTab.value].price,
            waregroupID: 0,
            editItemgroupID: this.itemgroupID,
            value: false,
          })
        }
      }

      this.loading = false;
      this.$emit("finish");
    },
  }
}
</script>